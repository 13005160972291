.navBar {
  position: sticky;
  top: 0;
  z-index: 1;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  color: rgb(23, 54, 80);
  :global(#basic-navbar-nav .nav-link) {
    color: rgb(23, 54, 80);
    &:hover {
      color: #007aff;
    }
  }
  .headerButton {
    border-color: #ffaf03 !important;
    background-color: #ffaf03 !important;
    font-family: "Poppins";
    color: white;
    &:hover {
      background-color: #ffbe33 !important;
    }
  }
  .solveLogo {
    height: 66px;
  }
}
